import { Box, Button, Checkbox, FormControlLabel, makeStyles, Modal, Paper, Typography } from '@material-ui/core'
import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import buzzEndpoints from '../../../api/buzz'
import { api } from '../../../api/common'
import Layout from '../../../components/Layout'
import SearchInput from '../../../components/SearchInput'
import { PaginationResponse } from '../../../models/api/buzz'
import { AdminActionType, AppealRequestParams, AppealResponse, AppealReportResponse, AppealReportRequestParams, AppealReportState } from '../../../models/api/buzz/appeal'
import { getParams } from '../../../utils/api'
import AppealReportWaitListItem from '../../../components/Appeal/AppealReportWaitList/AppealReportWaitListItem'
import UserModal from '../../../components/UserModal'
import AppealDetailModal from '../../../components/Appeal/Modal/AppealDetailModal'
import Masonry from 'react-masonry-css'

const breakpointCols = {
    default: 7,
    360: 1,
    720: 1,
    1080: 1,
    1440: 2,
    1800: 3,
    2160: 4,
    2520: 5,
    2880: 6,
}

const AppealReportWait = () => {

    const classes = useStyles()

    const [ showDelete, setShowDelete ] = useState<boolean>(false)
    const [ prevId, setPrevId ] = useState<number>(0)
    const [ appeals, setAppeals ] = useState<AppealReportResponse[]>([])
    const [ userModalOpened, setUserModalOpened ] = useState<boolean>(false)
    const [ userId, setUserId ] = useState<number>(0)
    const [ appealId, setAppealId ] = useState<number>(0)
    const [ detailModalOpened, setDetailModalOpened ] = useState<boolean>(false)

    const appealRef = useRef(appeals)
    const loader = useRef<HTMLDivElement>(null)
    const root = useRef<HTMLDivElement>(null)

    const { isLoading, isFetching, isRefetching, refetch } = useQuery<{ data: AppealReportResponse[] }>(
        ['appeals', prevId],
        async () => {
            let params: AppealReportRequestParams = {

            }
            if(prevId){
                params.prev = prevId
            }
            return api.get(`${buzzEndpoints.getAppealReports()}${getParams(params)}`).then(res => res.data)
        },
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnReconnect: false,
            onSuccess: (data) => {
                setAppeals(appeals => [...appeals, ...data.data])
                appealRef.current = data.data
            }
        }
    )

    const { mutate: updateReportState } = useMutation(
        (data: { id: number, state: string, data: { state: AppealReportState } }) => api.post(`${buzzEndpoints.updateReportState(data.id, data.state)}`, data.data).then(res => res.data),
    )

    const handleUpdateReport = (id: number, state: AdminActionType, reportState: AppealReportState) => {
        const endpointState = state==='Default' ? 'set_default' : state==='Approval' ? 'approve' : state.toLowerCase()
        updateReportState({
            id: id,
            state: endpointState,
            data: {
                state: reportState
            }
        }, {
            onSuccess: () => setAppeals(appeals => appeals.filter(appeal => appeal.id!==id))
        })
    }

    const handleUserModal = (id?: number) => {
        setUserId(id || 0)
        setUserModalOpened(open => !open)
    }

    const handleDetailModal = (id?: number) => {
        setAppealId(id || 0)
        setDetailModalOpened(open => !open)
    }

    const handleShowDelete = () => setShowDelete(show => !show)

    const handleRefresh = () => {
        setAppeals([])
        refetch()
    }

    const handleObserver: IntersectionObserverCallback = useCallback((entries) => {
        const target = entries[0]
        if(target.isIntersecting){
            if(appealRef.current){
                if(appealRef.current[appealRef.current.length - 1]){
                    setPrevId(appealRef.current[appealRef.current.length - 1].id)
                }
            }
        }
    }, [])

    useEffect(() => {
        const option = {
            root: root.current,
            rootMargin: '0px 0px 200px 0px',
            threshold: 0
        }
        const observer = new IntersectionObserver(handleObserver, option)
        if(loader.current) observer.observe(loader.current)
    }, [handleObserver])

    return (
        <Layout title='피드 대기 리스트' level='컨텐츠 관리' loading={isLoading || isFetching || isRefetching}>
            <Box className={classes.buttonBox}>
                <Button onClick={handleRefresh} color='primary' className={classes.button}>새로고침</Button>
            </Box>
            <Box className={classes.tableContainerBox}>
                <Masonry breakpointCols={breakpointCols} className={classes.tableContainer} columnClassName={classes.tableItem}>
                    {appeals.map(appeal => (
                        <AppealReportWaitListItem
                            {...appeal}
                            key={appeal.id}
                            onClickUser={handleUserModal}
                            onClickAction={handleUpdateReport}
                            onClickDetail={handleDetailModal}
                        />
                    ))}
                </Masonry>
                <div ref={loader} style={{ height: '20px' }}/>
            </Box>
            <Modal className={classes.modalContainer} open={userModalOpened} onClose={() => handleUserModal()}>
                <UserModal userId={userId}/>
            </Modal>
            <Modal className={classes.modalContainer} open={detailModalOpened} onClose={() => handleDetailModal()}>
                <AppealDetailModal id={appealId}/>
            </Modal>
        </Layout>
    )
}

export default AppealReportWait

const useStyles = makeStyles((theme) => ({
    buttonBox: {
    },
    button: {
        border: `1px solid ${theme.palette.primary.main}`
    },
    tableContainer: {
        borderRadius: 16,
        marginTop: 24,
        boxSizing: 'border-box',
        gap: 16,
        display: 'flex',
        width: '100%'
    },
    tableContainerBox: {
        height: `calc(100% - 60px)`,
        overflowY: 'scroll',
        width: '100%',
    },
    tableItem: {
        width: '340px!important'
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}))
