import { Box, Button, Checkbox, FormControlLabel, makeStyles, Modal, Paper, Typography, useTheme } from '@material-ui/core'
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react'
import { useMutation, useQuery } from 'react-query'
import buzzEndpoints from '../../../api/buzz'
import { api } from '../../../api/common'
import Layout from '../../../components/Layout'
import SearchInput from '../../../components/SearchInput'
import { PaginationResponse } from '../../../models/api/buzz'
import { AdminActionType, AppealRequestParams, AppealResponse } from '../../../models/api/buzz/appeal'
import { getParams } from '../../../utils/api'
import AppealListItem from '../../../components/Appeal/AppealList/AppealListItem'
import UserModal from '../../../components/UserModal'
import AppealDetailModal from '../../../components/Appeal/Modal/AppealDetailModal'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import Masonry from 'react-masonry-css'

const selectItem = [
    { label: '유저 아이디', value: 'id' },
    { label: '내용', value: 'content' },
    { label: '닉네임', value: 'nickname' }
]

const filterItem = [
    { label: '전체', value: 'all' },
    { label: '기본', value: 'Default' },
    { label: '인증', value: 'Approval' },
    { label: '숨김 & 제재', value: 'Hide' },
    { label: '삭제', value: 'Delete' }
]

const breakpointCols = {
    default: 7,
    360: 1,
    720: 1,
    1080: 1,
    1440: 2,
    1800: 3,
    2160: 4,
    2520: 5,
    2880: 6,
}

const AppealList = () => {

    const classes = useStyles()

    const [ keyword, setKeyword ] = useState<string>('')
    const [ searchType, setSearchType ] = useState<'id' | 'content' | 'nickname'>('id')
    const [ showDelete, setShowDelete ] = useState<boolean>(false)
    const [ prevId, setPrevId ] = useState<number>(0)
    const [ appeals, setAppeals ] = useState<AppealResponse[]>([])
    const [ userModalOpened, setUserModalOpened ] = useState<boolean>(false)
    const [ userId, setUserId ] = useState<number>(0)
    const [ appealId, setAppealId ] = useState<number>(0)
    const [ detailModalOpened, setDetailModalOpened ] = useState<boolean>(false)
    const [ filterType, setFilterType ] = useState<string>('all')

    const handleFilter = (e: unknown, value: string) => {
        appealRef.current = []
        setAppeals([])
        setPrevId(0)
        setFilterType(value || 'all')
    }

    const appealRef = useRef(appeals)
    const loader = useRef<HTMLDivElement>(null)
    const root = useRef<HTMLDivElement>(null)

    const theme = useTheme()

    const { isLoading, isFetching, isRefetching, refetch } = useQuery<{ data: AppealResponse[], pagination: PaginationResponse }>(
        ['appeals', prevId, filterType],
        async () => {
            let params: AppealRequestParams = {
                searchType: searchType,
                keyword: keyword,
                show_user_deleted_appeal: showDelete ? 'Y' : 'N',
                prev: prevId,
                type: filterType==='all' ? '' : filterType
            }
            return api.get(`${buzzEndpoints.getAppeals()}${getParams(params)}`).then(res => res.data)
        },
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnReconnect: false,
            onSuccess: (data) => {
                setAppeals(appeals => [...appeals, ...data.data])
                appealRef.current = data.data
            }
        }
    )

    const { mutate: updateAppealState } = useMutation(
        (data: { id: number, state: string, data: { lastAdminAction: AdminActionType } }) => api.post(`${buzzEndpoints.updateAppealState(data.id, data.state)}`, data.data).then(res => res.data),
    )

    const handleUpdateAppeal = (id: number, state: AdminActionType, lastAdminAction: AdminActionType) => {
        const endpointState = state==='Default' ? 'set_default' : state==='Approval' ? 'approve' : state.toLowerCase()
        updateAppealState({
            id: id,
            state: endpointState,
            data: {
                lastAdminAction: lastAdminAction
            }
        }, {
            onSuccess: () => setAppeals(appeals => appeals.map(appeal => appeal.id===id ? {...appeal, lastAdminAction: state} : appeal))
        })
    }

    const handleUserModal = (id?: number) => {
        setUserId(id || 0)
        setUserModalOpened(open => !open)
    }

    const handleDetailModal = (id?: number) => {
        setAppealId(id || 0)
        setDetailModalOpened(open => !open)
    }

    const handleShowDelete = () => setShowDelete(show => !show)

    const handleRefresh = () => {
        appealRef.current = []
        setAppeals([])
        setPrevId(0)
        if(prevId===0){
            refetch()
        }
    }

    const handleSearchType = (e: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {
        setSearchType(e.target.value as 'id' | 'content' | 'nickname')
    }

    const handleKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value)
    }

    const handleSearch = () => {
        appealRef.current = []
        setAppeals([])
        setPrevId(0)
        if(prevId===0){
            refetch()
        }
    }

    const handleObserver: IntersectionObserverCallback = useCallback((entries) => {
        const target = entries[0]
        if(target.isIntersecting){
            console.log('intersecting')
            if(appealRef.current){
                if(appealRef.current[appealRef.current.length - 1]){
                    setPrevId(appealRef.current[appealRef.current.length - 1].id)
                }
            }
        }
    }, [])

    useEffect(() => {
        const option = {
            root: root.current,
            rootMargin: '0px 0px 200px 0px',
            threshold: 0
        }
        const observer = new IntersectionObserver(handleObserver, option)
        if(loader.current) observer.observe(loader.current)
    }, [handleObserver])

    return (
        <Layout title='피드 전체 리스트' level='컨텐츠 관리' loading={isLoading || isFetching || isRefetching}>
            <Box className={classes.searchBoxContainer}>
                <Box className={classes.searchBox}>
                    <SearchInput
                        keyword={keyword}
                        onChangeKeyword={handleKeyword}
                        onChangeSelect={handleSearchType}
                        onClickSearch={handleSearch}
                        selectItem={selectItem}
                    />
                    <FormControlLabel
                        className={classes.checkbox}
                        control={
                            <Checkbox value={showDelete}
                                onChange={handleShowDelete}
                                color='default'
                            />
                        }
                        label={<Typography variant='body1' color='textPrimary'>유저 삭제 게시글 포함</Typography>}
                    />
                </Box>
                <Box className={classes.buttonBox}>
                    <ToggleButtonGroup
                        onChange={handleFilter}
                        value={filterType}
                        exclusive
                        className={classes.buttonGroupBox}
                    >
                        {filterItem.map(item => (
                            <ToggleButton
                                className={classes.button}
                                key={item.value}
                                value={item.value}
                                style={item.value===filterType ? { backgroundColor: theme.palette.primary.main, border: `1px solid ${theme.palette.primary.main}`, color: '#ffffff' } : {}}
                            >
                                {item.label}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                    <Button onClick={handleRefresh} color='primary' className={classes.button}>새로고침</Button>
                </Box>
            </Box>
            <Box className={classes.tableContainerBox}>
                <Masonry breakpointCols={breakpointCols} className={classes.tableContainer} columnClassName={classes.tableItem} /*columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }} defaultColumns={5} spacing={1}*/>
                    {appeals.map((appeal, index) => (
                        <AppealListItem
                            {...appeal}
                            key={String(appeal.id)}
                            onClickUser={handleUserModal}
                            onClickAction={handleUpdateAppeal}
                            onClickDetail={handleDetailModal}
                        />
                    ))}
                </Masonry>
                <div ref={loader} style={{ height: '20px' }}/>
            </Box>
            <Modal className={classes.modalContainer} open={userModalOpened} onClose={() => handleUserModal()}>
                <UserModal userId={userId}/>
            </Modal>
            <Modal className={classes.modalContainer} open={detailModalOpened} onClose={() => handleDetailModal()}>
                <AppealDetailModal id={appealId}/>
            </Modal>
        </Layout>
    )
}

export default AppealList

const useStyles = makeStyles((theme) => ({
    searchBoxContainer: {
        backgroundColor: theme.palette.background.default,
        paddingTop: 24,
        position: 'fixed',
        top: 100,
        width: 'calc(100% - 328px)',
        zIndex: 12
    },
    searchBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 16,
        width: '100%',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper,
        borderRadius: 16,
    },
    checkbox: {
        width: 250
    },
    buttonGroupBox: {
        marginRight: 12
    },
    buttonBox: {
        marginTop: 24
    },
    button: {
        padding: '5px 10px',
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableContainer: {
        borderRadius: 16,
        marginTop: 160,
        boxSizing: 'border-box',
        gap: 16,
        display: 'flex',
        width: '100%'
    },
    tableContainerBox: {
        height: `calc(100% - 140px)`,
        overflowY: 'scroll',
        width: '100%',
        backgroundColor: 'transparent'
    },
    tableItem: {
        width: '340px!important'
    }
}))
