import { IconButton, makeStyles, Modal, Paper, useTheme, Box } from '@material-ui/core'
import { Visibility } from '@material-ui/icons'
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useMutation, useQuery } from 'react-query'
import buzzEndpoints from '../../../api/buzz'
import { api } from '../../../api/common'
import AppealCommentListItem from '../../../components/Appeal/AppealCommentList/AppealCommentListItem'
import AppealDetailModal from '../../../components/Appeal/Modal/AppealDetailModal'
import Layout from '../../../components/Layout'
import SearchInput from '../../../components/SearchInput'
import UserModal from '../../../components/UserModal'
import usePagination from '../../../hooks/usePagination'
import { AdminActionType, AppealCommentResponse } from '../../../models/api/buzz/appeal'
import { getParams } from '../../../utils/api'

const selectItem = [
    { label: '유저아이디', value: 'id' },
    { label: '닉네임', value: 'nickname' },
    { label: '내용', value: 'body' },
]

const AppealCommentList = () => {

    const classes = useStyles()

    const theme = useTheme()

    const filterItem = [
        { label: '남성', value: 'male', color: theme.palette.info.main  },
        { label: '여성', value: 'female', color: theme.palette.error.main },
        { label: '댓글', value: 'is_parent_comment', color: theme.palette.grey[500] },
        { label: '대댓글', value: 'is_child_comment', color: theme.palette.grey[500]  },
        { label: '인증', value: 'is_approval_last_action', color: theme.palette.success.main },
        { label: '숨김', value: 'is_hide_last_action', color: theme.palette.warning.main },
        { label: '삭제', value: 'is_delete_last_action', color: theme.palette.error.main },
    ]

    const [ comments, setComments ] = useState<AppealCommentResponse[]>([])
    const [ keyword, setKeyword ] = useState<string>('')
    const [ searchType, setSearchType ] = useState<string>('id')
    const [ appealId, setAppealId ] = useState<number>(0)
    const [ detailModalOpened, setDetailModalOpened ] = useState<boolean>(false)
    const [ userId, setUserId ] = useState<number>(0)
    const [ userModalOpened, setUserModalOpened ] = useState<boolean>(false)
    const [ commentId, setCommentId ] = useState<number>(0)
    const { page, setPage, pageLength, setPageLength } = usePagination({
        initalPage: 0
    })
    const [ filterType, setFilterType ] = useState<string[]>(() => [])
    const [ markedId, setMarkedId ] = useState<number | null>(null)

    const loader = useRef<HTMLDivElement>(null)
    const root = useRef<HTMLDivElement>(null)
    const isLastPage = useRef<boolean>(false)

    const { isLoading, refetch } = useQuery<{ data: AppealCommentResponse[] }>(
        ['comments', filterType, page],
        async () => {
            let params = {
                male: filterType.includes('male') ? 'true' : 'false',
                female: filterType.includes('female') ? 'true' : 'false',
                is_parent_comment: filterType.includes('is_parent_comment') ? 'true' : 'false',
                is_child_comment: filterType.includes('is_child_comment') ? 'true' : 'false',
                is_approval_last_action: filterType.includes('is_approval_last_action') ? 'true' : 'false',
                is_hide_last_action: filterType.includes('is_hide_last_action') ? 'true' : 'false',
                is_delete_last_action: filterType.includes('is_delete_last_action') ? 'true' : 'false',
                search_type: searchType,
                keyword: keyword,
                order_by: 'desc'
            }
            return api.get(`${buzzEndpoints.getComments()}${getParams({ page: page, ...params })}`).then(res => res.data)
        },
        {
            onSuccess: (data) => {
                setComments(comments => [...comments, ...data.data.reverse()])
                if(data.data.length<100){
                    isLastPage.current = true
                } else {
                    isLastPage.current = false
                }
                console.log(data.data)
            }
        }
    )

    const { mutate: updateCommentState } = useMutation(
        (data: { id: number, state: string, data: { lastAdminAction: AdminActionType } }) => api.post(`${buzzEndpoints.updateCommentState(data.id, data.state)}`, data.data).then(res => res.data),
    )

    const handleSearch = () => {
        setComments([])
        setPage(0)
        if(page===0){
            refetch()
        }
    }
    const handleSelect = (e: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        setSearchType(e.target.value as string)
    }
    const handleAction = (id: number, state: AdminActionType, lastAdminAction: AdminActionType) => {
        const endpointState = state==='Default' ? 'set_default' : state==='Approval' ? 'approve' : state.toLowerCase()
        updateCommentState({
            id: id,
            state: endpointState,
            data: {
                lastAdminAction: lastAdminAction
            }
        }, {
            onSuccess: () => {
                setComments(comments => comments.map(comment => comment.id===id ? {...comment, lastAdminAction: state} : comment))
            }
        })
    }
    const handleDetailModal = (id?: number, commentId?: number) => {
        setAppealId(id || 0)
        setCommentId(commentId || 0)
        setDetailModalOpened(open => !open)
    }
    const handleUserModal = (id?: number) => {
        setUserId(id || 0)
        setUserModalOpened(open => !open)
    }
    const handleFilter = (e: React.MouseEvent, value: string[]) => {
        setFilterType(value as string[])
    }
    const handleMark = (id: number) => {
        setMarkedId(markedId => markedId===id ? null : id)
    }
    const handleObserver: IntersectionObserverCallback = useCallback((entries) => {
        const target = entries[0]
        if(target.isIntersecting){
            console.log('intersecting')
            if(!isLastPage.current){
                setPage(page => page + 1)
            }
        }
    }, [])

    useEffect(() => {
        const option = {
            root: root.current,
            rootMargin: '0px 0px 200px 0px',
            threshold: 0
        }
        const observer = new IntersectionObserver(handleObserver, option)
        if(loader.current) observer.observe(loader.current)
    }, [handleObserver])

    return (
        <Layout title='피드 댓글 리스트' level='컨텐츠 관리' filterItem={filterItem} exclusive={false} onFilterChange={handleFilter} filterValue={filterType} loading={isLoading}>
            <Box className={classes.searchBoxContainer}>
                <SearchInput
                    selectItem={selectItem}
                    keyword={keyword}
                    onChangeKeyword={e => setKeyword(e.target.value)}
                    onClickSearch={handleSearch}
                    onChangeSelect={handleSelect}
                />
            </Box>
            <Box className={classes.tableContainer}>
                {comments.map((comment, index) => (
                    <AppealCommentListItem
                        key={index}
                        {...comment}
                        onClickAction={handleAction}
                        onClick={handleDetailModal}
                        onClickUser={handleUserModal}
                        onClickMark={handleMark}
                        isMarked={comment.id===markedId}
                    />
                ))}
                <div ref={loader}/>
            </Box>
            <Modal className={classes.modalContainer} open={detailModalOpened} onClose={() => handleDetailModal()}>
                <AppealDetailModal id={appealId} selectedCommentId={commentId}/>
            </Modal>
            <Modal className={classes.modalContainer} open={userModalOpened} onClose={() => handleUserModal()}>
                <UserModal userId={userId}/>
            </Modal>
            <IconButton className={classes.markButton}  color='secondary'>
                <a href='#mark' className={classes.markTag}>
                    <Visibility color='action'/>
                </a>
            </IconButton>
        </Layout>
    )
}

export default AppealCommentList

const useStyles = makeStyles((theme) => ({
    searchBoxContainer: {
        backgroundColor: theme.palette.background.default,
        paddingTop: 24,
        position: 'fixed',
        top: 100,
        width: 'calc(100% - 328px)',
        zIndex: 12
    },
    tableContainer: {
        borderRadius: 16,
        marginTop: 120,
        boxSizing: 'border-box',
        gap: 16,
        overflowY: 'scroll',
    },
    modalContainer: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    markButton: {
        position: 'fixed',
        bottom: 20,
        right: 20,
        backgroundColor: '#e91e63',
        "&:hover": {
            backgroundColor: '#e91e63',
        }
    },
    markTag: {
        height: 24,
        width: 24
    }
}))
